import React, { createContext, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import IUser from '@/interfaces/IUser'
import $Auth from '@/services/Auth'
import useClient from '@/hooks/useClient'

type AuthContextProps = {
  user: IUser
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

type Props = {
  token: string | null
}

export const AuthProvider: React.FC<Props> = ({ token }) => {
  const $user = useQuery({
    queryFn: $Auth.me,
    queryKey: [
      'user', token,
    ],
  })

  const user = $user.data ?? null

  const setClient = useClient()

  useEffect(() => {
    if (user) {
      setClient(user.client)
    }
  }, [setClient, user])

  return (
    <AuthContext.Provider
      value={{
        user: user as IUser,
      }}
    >
      {user ? (
        <Outlet />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </AuthContext.Provider>
  )
}

export default AuthContext
