import React, { useCallback } from 'react'
import IClient from '@/interfaces/IClient'
import { hexToRgb } from '@/helpers/Color'

const useClient = () => {
  return useCallback(({
    name,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    quaternaryColor
  }: IClient) => {
    document.title = name

    const colors = {
      '--primary-color': primaryColor,
      '--secondary-color': secondaryColor,
      '--tertiary-color': tertiaryColor,
      '--quaternary-color': quaternaryColor,
    }

    Object.entries(colors).forEach(([key, hex]) => {
      document.documentElement.style.setProperty(key, hex);
      document.documentElement.style.setProperty(key + '-rgb', hexToRgb(hex).join(', '));
    })
  }, [])
}

export default useClient
